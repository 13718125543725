import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../assets/images/Success.png";
import errorImage from "../../../assets/images/error.png";
import { useNavigate } from "react-router-dom";

import "./index.scss";

const SuccessPrompt = (props) => {
  const successStatus = props.success;
  const [showModal, setShowModal] = useState(props.showStatus);
  const onCLickingClose = () => {
    setShowModal(false)
    console.log("Hi");
   
    props.handleClose()
  }
  useEffect(() => {
    if (props.showStatus) {
      setShowModal(true);
      const timer = setTimeout(() => {
        setShowModal(false);
        props.setOpenSuccess(false)
      }, 2000);

      // Clear timer if component unmounts or showStatus changes
      return () => clearTimeout(timer);
    }
  }, [props.showStatus]);

  return (
    <>
      <Modal
        show={showModal}
        className="ModalOuterDiv"
        onHide={props.handleClose}
        centered
      >
        <Modal.Body>
          <div className="successModalBody">
            {successStatus === true && (
              <>
                <div className="successModalBodyContainer">
                  <div className="successModalBodyInner">
                    <img
                      src={successImage}
                      alt="image loading failed...."
                      className="modalBodyImage"
                    />

                    <p className="successText">
                      {props.message
                        ? props?.message?.charAt(0).toUpperCase() +
                          props?.message?.slice(1)
                        : ""}
                    </p>
                    <button className="btnClose" onClick={()=>onCLickingClose()}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}

            {successStatus === false && (
              <>
                <div className="successModalBodyContainer">
                  <div className="successModalBodyInner">
                    <img
                      src={errorImage}
                      alt="image loading failed...."
                      className="modalBodyImage"
                    />
                    <p className="successText">
                      {props.message.charAt(0).toUpperCase() +
                        props.message.slice(1)}
                    </p>
                    <button className="btnClose" onClick={()=>onCLickingClose()}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SuccessPrompt;
