import axios from "axios";
import getEndpoints from "../config/endpoints";

const authenticationManagementAPIs = {
  login: async (email, password) => {
    try {
      const endpoint = getEndpoints().authentication.login;
      const body = { email, password };
      const response = await axios.post(endpoint, body);
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  portfolioBroker: async (token, user_id) => {
    try {
      const endpoint = getEndpoints().brokerPortfolio.portfolio;
      const body = { user_id };
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  portfolioBrokerUpdate: async (token, body) => {
    try {
      const endpoint = getEndpoints().brokerPortfolio.update;
     
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },          
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  createAccountBroker: async (token, body) => {
    try {
      const endpoint = getEndpoints().createAccount.broker;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  portfolioGroupAdmin: async (token, user_id) => {
    try {
      const endpoint = getEndpoints().groupadmin.portfolio;
      const body = { user_id };
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  portfolioGroupMember: async (token, user_id) => {
    try {
      const endpoint = getEndpoints().groupmember.portfolio;
      const body = { user_id };
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  groupAdminMarketPublic: async (token, user_id) => {
    try {
      const endpoint = getEndpoints().groupadmin.market.public;
      const body = { user_id };
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  groupAdminMarketPrivate: async (token, user_id) => {
    try {
      const endpoint = getEndpoints().groupadmin.market.private;
      const body = { user_id };
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  caskRequestIndividual: async (token, body) => {
    try {
      const endpoint = getEndpoints().caskRequest.individual;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  caskType: async (token, body) => {
    try {
      const endpoint = getEndpoints().caskType.fetch;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  caskRequestIndividualSurpriseMe: async (token, body) => {
    try {
      const endpoint = getEndpoints().caskRequest.surpriseMe;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  MembershipVotingCreate: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupadmin.membershipVoting.create;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  MembershipVotingDelete: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupadmin.membershipVoting.delete;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },

  MembershipVotingView: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupadmin.membershipVoting.view;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  GMPublicMarketplace: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupmember.marketplace.public;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  GMPublicMarketplaceLikeUpdate: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupmember.marketplace.updatelike;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  GMPrivateMarketplace: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupmember.marketplace.private;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  ForgotPassword: async (body) => {
    try {
      const endpoint = getEndpoints().authentication.forgotPassword;
      const response = await axios.post(endpoint, body);
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  ResetPassword: async (body) => {
    try {
      const endpoint = getEndpoints().authentication.resetPassword;
      const response = await axios.post(endpoint, body);
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  createAccountGroupMember: async (token, body) => {
    try {
      const endpoint = getEndpoints().createAccount.groupmember;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  MembershipVotingGroupMember: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupmember.membershipVoting.view;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  MembershipVotingUpdateGroupMember: async (token, body) => {
    try {
      const endpoint = getEndpoints().groupmember.membershipVoting.updateVote;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  SuperAdminPayOutView: async (token, body) => {
    try {
      const endpoint = getEndpoints().superAdmin.payOut.view;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
  SuperAdminPayOutAction: async (token, body) => {
    try {
      const endpoint = getEndpoints().superAdmin.payOut.action;
      const response = await axios.post(endpoint, body, {
        headers: {
          token: token,
        },
      });
      return response;
    } catch (e) {
      return e.response || e;
    }
  },
};

export default authenticationManagementAPIs;
