import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import "./index.scss";
import getEndpoints from "../../../../config/endpoints";
import { getUserData } from "../../../../utils/helperFunctions";
import axios from "axios";
import SuccessPrompt from "../../../../components/Shared/SuccessPrompt/SuccessPrompt";

export default function FeedbackAddModal(props) {
  const profileData = getUserData();
  const [modalBoxShow, setModalBoxShow] = useState(props.setDisplay);
  const memberData = props.memberData;
  const [errorState, setErrorState] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [confirmButtonStatus, setConfirmButtonStatus] = useState(true);
  const [feedback, setFeedback] = useState();
  
  const hideTrigger = props.hideTriggerFunc;
  
  const onClose = () => {
    setModalBoxShow(false);
  };
  const hideTriggerFunc = () => {
   
    hideTrigger();
    
  };
  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("feedback_id", "None");
    formData.append("feedback", feedback);
    try {
      const endpoint = getEndpoints().feedback.create;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      console.log("Response", response)
      setModalShow(true)
      // props.handleModalAction(1)
      if (response.data.success) {
        props.handleClose(2)
      } else {
        props.handleClose(3)
      }
    
      
      // setWalletData(response.data[0]);
      return response;
    } catch (e) {
      props.handleClose(3)
      return e.response || e;
    }
  };
  

  const onFeedbackChange = (e) => {
    console.log(e,"e",e,"e.textare",e.target.value)
    setFeedback(e.target.value);
    e.target.value ? setConfirmButtonStatus(false) : setConfirmButtonStatus(true);
  };
  useEffect(() => {
    console.log("props", props);
    setModalBoxShow(props.setDisplay);
  }, [props]);
  return (
    <>
     
      <Modal show={modalBoxShow} className="ModalOuterDiv">
        <Modal.Header closeButton onClick={()=>props.handleClose(1)}>
        <div className="memberModalHeadingAdd">Add Your Feedback  </div>
        </Modal.Header>
        <Modal.Body>
          <div className="feedbackModalBody">
            
            
                <div className="memberInputRow">
                  <textarea
                  
                    rows="10" // Specifies the number of visible text lines
                    cols="100" // Specifies the width of the textarea (in characters)
                    // options={nameOptions}
                    onChange={(e) => {onFeedbackChange(e)}}
                    // defaultValue={name}
                style={{
                  border: '1px solid #ccc',
                  borderRadius: '10px',
                      maxHeight: '200px', // Sets the maximum height for the textarea
                      overflow: 'auto', // Adds a scrollbar if content overflows
                      resize: 'none', // Optional: Disable manual resizing by the user
                    }}
                  />
                </div>
             
            </div>
        </Modal.Body>
        <Modal.Footer>
        {/* <div className="btnRowError"> */}
              <button
                className="btnSubFeedback"
                onClick={() => handleSubmit()}
                disabled={confirmButtonStatus}
              >
                SUBMIT
              </button>
          <button className="btnCancelFeedback"
            onClick={() => props.handleClose(1)}>
                CANCEL
              </button>
            {/* </div> */}
          
        </Modal.Footer>
      </Modal>
    </>
  );
}
