import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./index.scss";
const Menu = ({ item, upcoming }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  const location = useLocation();
  const indicator = location.pathname;
  return (
    <>
      <div className="sidebar-brkr-2">
        <NavLink
          to={item?.path}
          className="sideBarLinkBrkr"
          onClick={item?.subNav && showSubnav}
        >
          <div className="nav-link-brkr">
            {item?.icon}
            <span className="sideBarLabelBrkr">{item?.title}</span>
            <div
              className={
                item?.path && item?.path == indicator ? "show-indicator" : " "
              }
            ></div>
            {item?.subNav && subnav
              ? item?.iconOpened
              : item?.subNav
              ? item?.iconClosed
              : null}
          </div>
        </NavLink>

        {subnav &&
          item?.subNav.map((item, index) => {
            return (
              <div className="dropDownLinkBrkr" key={index}>
                <NavLink
                  to={item?.path}
                  className={
                    item?.path && item?.path == indicator
                      ? "dropDownLinkBrkr-c show-indicator-c"
                      : "dropDownLinkBrkr-c "
                  }
                  key={index}
                >
                  {item?.icon}
                  <span className="sideBarSubLabelBrkr">{item?.title}</span>
                </NavLink>
              </div>
            );
          })}
        <NavLink
          to={null}
          className="sideBarLinkUpcmng"
          // onClick={upcoming.subNav && showSubnav}
        >
          <div className="nav-link-upcmng">
            {upcoming?.icon}
            <span className="sideBarLabelBrkrUpcmg">{upcoming.title}</span>
            <div
              className={
                upcoming.path && upcoming.path == indicator
                  ? "show-indicator"
                  : " "
              }
            ></div>
          </div>
        </NavLink>
      </div>
    </>
  );
};

export default Menu;
