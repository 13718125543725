import React, { useState, useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import BuyCaskModal from "./BuyCaskModal/BuyCaskModal";
import leftArrow from "../../../assets/images/left-arrow.svg";

const BuyCaskMultiple = (props) => {
  let [caskIds, setCaskIds] = useState(JSON.stringify(props.caskIds));
  let [navigater, setNavigater] = useState(0);
  let [caskResponseMultiple, setCaskResponseMultiple] = useState([]);
  let [caskData, setCaskData] = useState(caskResponseMultiple[0]);
  let [caskTotalData, setCaskTotalData] = useState(null);
  let [showBuyCaskModal, setShowBuyCaskModal] = useState({
    show: false,
    success: false,
  });
  const [isAPiLoading, setIsAPiLoading] = useState(false);
  const [walletData, setWalletData] = useState(null);
  let navigate = useNavigate();
  const profileData = getUserData();
  const PortfolioRedirector = () => {
    navigate("/individualadmin/marketplace/private");
  };
  const walletInfo = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().wallet.walletInfo;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setWalletData(response.data[0]);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const totalPriceCalc = () => {
    let tot_price = 0;
    if (caskResponseMultiple !== null) {
      for (var i = 0; i < Object.keys(caskResponseMultiple).length; i++) {
        tot_price = tot_price + caskResponseMultiple[i].price;
      }
    }
    return tot_price;
  };
  const caskPriceCalc = () => {
    let price_set = [];
    if (caskResponseMultiple !== null) {
      for (var i = 0; i < Object.keys(caskResponseMultiple).length; i++) {
        price_set.push(caskResponseMultiple[i].price);
      }
    }
    return price_set;
  };
  const leftArrowHandler = () => {
    if (navigater - 1 >= 0) {
      setNavigater(navigater - 1);
      setCaskData(caskResponseMultiple[navigater - 1]);
      setCaskTotalData(false);
    }
  };
  const rightArrowHandler = () => {
    if (
      navigater + 1 >= 0 &&
      navigater + 1 < Object.keys(caskResponseMultiple).length
    ) {
      setNavigater(navigater + 1);
      setCaskData(caskResponseMultiple[navigater + 1]);
    } else {
      setCaskData(null);
      setCaskTotalData(true);
    }
  };
  const triggerMultiSelect = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("cask_id_collection", caskIds);
    try {
      const endpoint = getEndpoints().groupadmin.market.private_multiple;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      if (response !== null) {
        setCaskData(response.data[0]);
        setCaskResponseMultiple(response.data);
      }
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const buyCaskHandler = async () => {
    // Wallet Checking is disabled for the new update
    // if (
    //   walletData.available_amt -
    //     (totalPriceCalc() + (totalPriceCalc() / 100) * 10) <
    //   0
    // ) {
    //   setShowBuyCaskModal({
    //     show: true,
    //     success: false,
    //   });
    //   return;
    // }
    setIsAPiLoading(true)
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("cask_id_collection", JSON.stringify(props.caskIds));
    formData.append("cask_price_collection", JSON.stringify(caskPriceCalc()));
    formData.append(
      "total_price",
      totalPriceCalc() + (totalPriceCalc() / 100) * 10
    );

    try {
      const endpoint = getEndpoints().groupadmin.cask.buy_multiple;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == "True") {
        setIsAPiLoading(false)
        setShowBuyCaskModal({
          show: true,
          success: true,
        });
      } else {
        setIsAPiLoading(false)
        setShowBuyCaskModal({
          show: true,
          success: false,
        });
      }
      return response;
    } catch (e) {
      setIsAPiLoading(false) 
      setShowBuyCaskModal({
        show: true,
        success: false,
      });
      return e.response || e;
    }
  };
  useEffect(() => {
    walletInfo();
    triggerMultiSelect();
  }, []);

  return (
    <>
      <BuyCaskModal
        showStatus={showBuyCaskModal}
        dataFunction={PortfolioRedirector}
      />
      <div className="caskHeader">
        <h6>CASK DETAILS</h6>
        <div onClick={PortfolioRedirector} className="portfolioRedirector">
          <img src="assets/images/icons/left-arrow.svg" alt="" />
          {"<Back To Portfolio"}
        </div>
      </div>
      <div className="caskBody">
        <div className="caskBuyContainer">
          {caskData && (
            <div>
              <div className="caskRow">
                <p>Details</p>
              </div>
              <div className="caskRow">
                <h6>Cask :</h6>
                <p>
                  {caskData.distillery}, {caskData.casktype}
                </p>
              </div>
              <div className="caskRow">
                <h6>AYS :</h6>
                <p>{caskData.ays}</p>
              </div>
              <div className="caskRow">
                <h6>ABV :</h6>
                <p>{caskData.abv}</p>
              </div>
              <div className="caskRow">
                <h6>OLA :</h6>
                <p>{caskData.ola_rla}</p>
              </div>
              <div className="caskRow">
                <p>Cost Breakdown</p>
              </div>
              <div className="caskRow">
                <h6>Cask Price :</h6>
                <p>£{PriceComponent(caskData.price)}</p>
              </div>
              {/* <div className="caskRow">
                <h6>Transaction Fee :</h6>
                <p>£{PriceComponent((caskData.price / 100) * 10)}</p>
              </div> */}
              <div className="caskRow">
                <h6>Total Purchase Price:</h6>
                <p>
                  £
                  {PriceComponent(
                    totalPriceCalc()
                    // +
                    // (totalPriceCalc() / 100) * 10
                  )}
                </p>
              </div>
              {/* <div className="caskRow">
                <p>Balance</p>
              </div>
              <div className="caskRow">
                <h6>Available Balance :</h6>
                <p>
                  £{walletData && PriceComponent(walletData.available_amt)}
                </p>
              </div>
              <div className="caskRow">
                <h6>New Balance :</h6>
                <p>
                  {walletData &&
                    walletData.available_amt -
                      (totalPriceCalc() + (totalPriceCalc() / 100) * 10) <
                      0 && (
                      <div className="minusBalanceText">
                        £
                        {walletData &&
                          PriceComponent(
                            walletData.available_amt -
                            (totalPriceCalc() + (totalPriceCalc() / 100) * 10)
                          )}
                      </div>
                    )}
                  {walletData &&
                    walletData.available_amt -
                      (totalPriceCalc() + (totalPriceCalc() / 100) * 10) >
                      0 && (
                      <div>
                        £
                        {walletData &&
                          PriceComponent(
                            walletData.available_amt -
                            (totalPriceCalc() + (totalPriceCalc() / 100) * 10)
                          )}
                      </div>
                    )}
                </p>
              </div> */}
            </div>
          )}
          {caskTotalData && (
            <div>
              <div className="caskRow">
                <h6>No</h6>
                <h6>Selected Casks</h6>
              </div>
              {caskResponseMultiple &&
                Object.keys(caskResponseMultiple).map((index) => {
                  return (
                    <div className="caskRow" key={index}>
                      <h6>{Number(index) + 1}</h6>
                      <p>
                        {caskResponseMultiple[index].distillery +
                          " , " +
                          caskResponseMultiple[index].casktype}
                      </p>
                    </div>
                  );
                })}
              <div className="caskRow">
                <p>Billing :</p>
              </div>
              <div className="caskRow">
                <h6>Total Cask Price :</h6>
                <p> £{PriceComponent(totalPriceCalc())}</p>
              </div>
              {/* <div className="caskRow">
                <h6>Transaction Fee :</h6>
                <p> £{PriceComponent((totalPriceCalc() / 100) * 10)}</p>
              </div> */}
              <div className="caskRow">
                <h6>Total Amount:</h6>
                <p>
                  £
                  {PriceComponent(
                    totalPriceCalc()
                    // +
                    // (totalPriceCalc() / 100) * 10
                  )}
                </p>
              </div>
              {/* <div className="caskRow">
                <p>Balance</p>
              </div>
              <div className="caskRow">
                <h6>Available Balance :</h6>
                <p>
                  £{walletData && PriceComponent(walletData.available_amt)}
                </p>
              </div> */}
              {/* <div className="caskRow">
                <h6>New Balance :</h6>
                <p>
                  {walletData &&
                    walletData.available_amt -
                      (totalPriceCalc() + (totalPriceCalc() / 100) * 10) <
                      0 && (
                      <div className="minusBalanceText">
                        £
                        {walletData &&
                          PriceComponent(
                            walletData.available_amt -
                            (totalPriceCalc() + (totalPriceCalc() / 100) * 10)
                          )}
                      </div>
                    )}
                  {walletData &&
                    walletData.available_amt -
                      (totalPriceCalc() + (totalPriceCalc() / 100) * 10) >
                      0 && (
                      <div>
                        £
                        {walletData &&
                          PriceComponent(
                            walletData.available_amt -
                            (totalPriceCalc() + (totalPriceCalc() / 100) * 10)
                          )}
                      </div>
                    )}
                </p>
              </div> */}
            </div>
          )}
          {navigater !== 0 && (
            <img
              src={leftArrow}
              alt="left arrow"
              className="leftArrow"
              onClick={leftArrowHandler}
            />
          )}
          {navigater + 1 <= Object.keys(caskResponseMultiple).length + 1 && (
            <img
              src={leftArrow}
              alt="right arrow"
              className="rightArrow"
              onClick={rightArrowHandler}
            />
          )}

          <div className="sellFooter">
            <button className="greenBtn" onClick={buyCaskHandler}
            disabled={isAPiLoading}>
            REQUEST
            </button>
            <button className="redBtn" onClick={PortfolioRedirector}>
              CANCEL
            </button>
          </div>
        </div>

        <h6 className="caskBodyBottom">
          By clicking <b>Request</b>, you agree to BitCask's{" "}
          <b>
            <a href="https://www.bit-cask.com/tc" className="" target="_blank">
              terms and conditions
            </a>
          </b>
          . Your payment will be put in pending until we secure your cask. If
          successful, your cask will be available to view in your{" "}
          <b>Portfolio</b>. If unavailable you will be fully refunded.
        </h6>
      </div>
    </>
  );
};
export default BuyCaskMultiple;
