import React, { useEffect, useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import BuyCaskModal from "./BuyCaskModal/BuyCaskModal";
const GroupMemberBuyCask = (props) => {
  let [buycaskData, setBuycaskData] = useState(props.caskData);
  let [shares, setShares] = useState("");
  let [shareError, setShareError] = useState("");
  let [buttonDisable, setButtonDisable] = useState(true);
  let [showBuyCaskModal, setShowBuyCaskModal] = useState({
    show: false,
    success: false,
  });
  const [addButtonShow, setAddButtonShow] = useState(true);
  const [minusButtonShow, setMinusuttonShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  let navigate = useNavigate();
  const profileData = getUserData();
  const PortfolioRedirector = () => {
    navigate("/groupmember/marketplace/private");
  };

  const buyCaskHandler = async () => {
    if (shareError === "") {
      let formData = new FormData();
      formData.append("cask_id", buycaskData.cask_id);
      formData.append("user_id", profileData.user_id);

      formData.append("noOfShares", shares);
      formData.append("sharesPrice", buycaskData.share_price);
      formData.append(
        "totalPrice",
        buycaskData.share_price * shares + platformFee
      );

      try {
        const endpoint = getEndpoints().groupmember.cask.buy;
        const response = await axios.post(endpoint, formData, {
          headers: { token: profileData.token },
        });
        if (response.data.success == true) {
          setShowBuyCaskModal({
            show: true,
            success: true,
          });
          setButtonDisable(true);
          setShares("");
          setSubtotal(0);
          setTotal(0);
          setPlatformFee(0);
        } else {
          setShowBuyCaskModal({
            show: true,
            success: false,
          });
        }
        return response;
      } catch (e) {
        setShowBuyCaskModal({
          show: true,
          success: false,
        });
        return e.response || e;
      }
    } else {
      setButtonDisable(true);
      setShareError(
        "* Please provide a value in between 1 & " +
          buycaskData.available_shares
      );
    }
  };
  const ModalClose = () => {
    setShowBuyCaskModal({
      show: false,
      success: true,
    });
    PortfolioRedirector();
  };
  const validateShare = (share) => {
    setShares(share);
    var pattern = /(^$)|(^[0-9]+$)/;
    if (pattern.test(share) == true) {
      if (share < buycaskData.available_shares && share > 1) {
        setSubtotal(buycaskData.share_price * share);
        setPlatformFee(buycaskData.share_price * share * (2.5 / 100));
        setTotal(
          buycaskData.share_price * share +
            buycaskData.share_price * share * (2.5 / 100)
        );
        setShareError("");
        setButtonDisable(false);
        setAddButtonShow(true);
        setMinusuttonShow(true);
      } else if (Number(share) === buycaskData.available_shares) {
        setSubtotal(buycaskData.share_price * share);

        setPlatformFee(buycaskData.share_price * share * (2.5 / 100));
        setTotal(
          buycaskData.share_price * share +
            buycaskData.share_price * share * (2.5 / 100)
        );
        setShareError("");
        setButtonDisable(false);
        setAddButtonShow(false);
        setMinusuttonShow(true);
      } else if (share > buycaskData.available_shares) {
        setButtonDisable(true);
        setTotal(0);
        setSubtotal(0);
        setPlatformFee(0);
        setShareError(
          "* Please provide a value in between 1 & " +
            buycaskData.available_shares
        );
        setAddButtonShow(false);
        setMinusuttonShow(true);
      } else if (Number(share) === 1) {
        setSubtotal(buycaskData.share_price * share);

        setPlatformFee(buycaskData.share_price * share * (2.5 / 100));
        setTotal(
          buycaskData.share_price * share +
            buycaskData.share_price * share * (2.5 / 100)
        );
        setButtonDisable(false);
        setMinusuttonShow(false);
      } else {
        setSubtotal(0);
        setTotal(0);
        setPlatformFee(0);
        setButtonDisable(true);
        setMinusuttonShow(false);
      }
    } else {
      setTotal(0);
      setSubtotal(0);
      setPlatformFee(0);
      setButtonDisable(true);
      setShareError("* Please provide a valid input");
    }
  };
  const addTrigger = () => {
    const shareValueInc = Number(shares) + 1;
    validateShare(shareValueInc);
  };
  const minusTrigger = () => {
    validateShare(shares - 1);
  };

  return (
    <>
      <BuyCaskModal showStatus={showBuyCaskModal} dataFunction={ModalClose} />
      <div className="caskHeader">
        <h6>CASK DETAILS</h6>
        <div onClick={PortfolioRedirector} className="portfolioRedirector">
          <img src="assets/images/icons/left-arrow.svg" alt="" />
          {"<Back To Private Marketplace"}
        </div>
      </div>
      <div className="caskBody">
        <h6 className="caskBodyText">
          Please fill out the form to buy available shares.
        </h6>
        <div className="caskBuyContainer ">
          <div className="caskRowGM">
            <p>Cask Details</p>
          </div>
          <div className="caskRowGM">
            <h6>Cask :</h6>
            <p>
              {buycaskData.distillery}, {buycaskData.casktype}
            </p>
          </div>
          <div className="caskRowGM">
            <h6>AYS :</h6>
            <p>{buycaskData.ays}</p>
          </div>
          <div className="caskRowGM">
            <h6>ABV :</h6>
            <p>{buycaskData.abv} %</p>
          </div>
          <div className="caskRowGM">
            <h6>OLA :</h6>
            <p>{buycaskData.ola_rla}</p>
          </div>
          <div className="line">
            -------------------------------------------------------
          </div>
          <div className="caskRowGM">
            <p>Share Details</p>
          </div>
          <div className="caskRowGM">
            <h6>Shares Available :</h6>
            <p>
              {buycaskData.available_shares}/{buycaskData.total_shares}
            </p>
          </div>
          <div className="caskRowGM">
            <h6>Share Price :</h6>
            <p>£{PriceComponent(buycaskData.share_price)}</p>
          </div>
          <div className="caskRowGM">
            <h6>Shares :</h6>
            <div
              onClick={minusTrigger}
              className={minusButtonShow === true ? "" : "addButton"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </div>

            <input
              type="text"
              name="buyshare"
              className="h"
              value={shares}
              onChange={(e) => validateShare(e.target.value)}
            />
            <div
              onClick={addTrigger}
              className={addButtonShow === true ? "" : "addButton"}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </div>
          </div>
          {/* <p className="error">{shareError}</p> */}
          <div className="line">
            -------------------------------------------------------
          </div>
          <div className="caskRowGM">
            <p>Cost Breakdown</p>
          </div>
          <div className="caskRowGM">
            <h6>Subtotal:</h6>
            <p>£{PriceComponent(subtotal)}</p>
          </div>
          {/* <div className="caskRowGM">
            <h6>Platform Fee :</h6>
            
            <p>£{PriceComponent(platformFee)}</p>
          </div> */}
          <div className="caskRowGM">
            <h6>Total :</h6>
            <p>£{PriceComponent(total)}</p>
          </div>

          <div className="sellFooter">
            <button
              className={buttonDisable === true ? "disable" : "greenBtn"}
              onClick={buyCaskHandler}
            >
              PURCHASE
            </button>
            <button className="redBtn" onClick={PortfolioRedirector}>
              CANCEL
            </button>
          </div>
        </div>
        <h6 className="caskBodyBottom">
          By clicking <b>Purchase</b>, you agree to BitCask's
          <b>
            <a href="https://www.bit-cask.com/tc" className="" target="_blank">
              terms and conditions
            </a>
          </b>
          . Your payment will be put in pending until we secure your cask. If
          successful, your cask will be available to view in your
          <b>Portfolio</b>. If unavailable you will be fully refunded.
        </h6>
      </div>
    </>
  );
};
export default GroupMemberBuyCask;
