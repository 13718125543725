import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function deletePromptModal(props) {
  const confirmFunc = props.confirmFunc;
  const closeFunc = props.closeFunc;
  let disable = props.disable;
  console.log("deletePromptModal",disable,props.disable)

  return (
    <div
      className="modal show"
      style={{ display: "block", position: "initial" }}
    >
      <Modal show={props.show} onHide={closeFunc}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion..</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure, You want to delete this?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={confirmFunc}
          disable={disable}
          >
            Confirm
          </Button>
          <Button variant="success" onClick={closeFunc}
          disable={disable}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default deletePromptModal;
