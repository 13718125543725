import React, { useState, useEffect } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import {
  PriceComponent,
  getToken,
  getUserData,
} from "../../../utils/helperFunctions";
import "./index.scss";
import CancelImg from "../../../assets/images/close.png";
import SaveImg from "../../../assets/images/accept.png";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import DeletePromptModal from "../../../components/Shared/DeletePrompt/deletePromptModal";

const BorkerPortfolio = (props) => {
  const profileDatas = getUserData();
  console.log(profileDatas, "profile");
  const [responseDatas, setResponseDatas] = useState([]);
  const [editRowId, setEditRowId] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [editAPILoading, setEditAPILoading] = useState(false);
  const [deleteAPILoading, setDeleteAPILoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userData = getUserData();
  const formatTwoDecimal = (value: number) => value.toFixed(2);
  const token = getToken();
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditClick = (responseData) => {
    console.log("responseData", responseData);
    setEditRowId(responseData.cask_id);
    setEditedData(responseData); // Initialize the edited data with current row data
  };

  const handleSaveClick = () => {
    console.log("editedData", editedData);
    // Call update API with the edited data
    // updateApi(editedData);
    updateData();

    // Reset the edit state after saving
  };
  const fetchData = async () => {
    try {
      const res = await authenticationManagementAPIs.portfolioBroker(
        token,
        JSON.stringify(userData.user_id)
      );
      console.log("res,res", res, "res,res,", res.data);
      if (res) {

        await setResponseDatas(res.data);
        const totalPrice = res.data.reduce(
          (sum, item) => sum + (item.price || 0),
          0
        );
        console.log("totalPrice: " + totalPrice)
        props.setTotalAmount(totalPrice);
        setEditRowId(null);
      }
    } catch (e) {
      return e.response || e;
    }
  };
  const updateData = async () => {
    try {
      setEditAPILoading(true);
      let formData = new FormData();
      formData.append("user_id", profileDatas?.user_id);
      formData.append("cask_id", editedData.cask_id);
      formData.append("distillery", editedData.distillery);
      formData.append("cask_type", editedData.casktype);
      formData.append("ays", editedData.ays);
      formData.append("loa", editedData.loa);
      formData.append("ola_rla", editedData.ola_rla);

      const priceValue = String(editedData.price); // Convert to string

      if (priceValue.includes(",")) {
        formData.append("price", priceValue.replace(/,/g, ""));
      } else {
        formData.append("price", priceValue);
      }

      const res = await authenticationManagementAPIs.portfolioBrokerUpdate(
        token,
        formData
      );

      if (res.data.data === "success") {
        await fetchData();
        setEditAPILoading(false);
      } else {
        setEditAPILoading(false);
      }
      // setResponseDatas(res.data);
    } catch (e) {
      setEditAPILoading(false);
      return e.response || e;
    }
  };
  const handleDeleteClick = (cask) => {
    setDeleteRowId(cask.cask_id);
    setShowModal(true);

    // deleteCask();
  };
  const deleteCask = async (caskId) => {
    setDeleteAPILoading(true);
    let formData = new FormData();
    formData.append("user_id", profileDatas?.user_id);
    formData.append("cask_id", caskId);
    try {
      const endpoint = getEndpoints().brokerPortfolio.delete;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileDatas.token },
      });
      if (response.data.success === true) {
        fetchData();
        setDeleteAPILoading(false);
      }

      return response;
    } catch (e) {
      setDeleteAPILoading(false);
      return e.response || e;
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    console.log("responseDatas", responseDatas);
  }, [responseDatas]);
  const triggerdDelete = async () => {
    await deleteCask(deleteRowId);
    setShowModal(false);
  };
  const triggerdDeleteHide = () => {
    setShowModal(false);
  };

  return (
    <>
      <DeletePromptModal
        show={showModal}
        confirmFunc={triggerdDelete}
        closeFunc={triggerdDeleteHide}
        disable={deleteAPILoading}
      />
      <div>
        <table className="broker-portfolio-table">
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>

              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
              <th scope="col">ACTIONS</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {responseDatas?.length > 0 &&
              responseDatas?.map((responseData) => (
                <tr key={responseData.cask_id} className="portfolioBrkrDataRow">
                  <td data-label="Name">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="distillery"
                        value={editedData.distillery}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                        disable={editAPILoading}
                      />
                    ) : (
                      responseData.distillery
                    )}
                  </td>
                  <td data-label="Date">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="ays"
                        value={editedData.ays}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      responseData.ays
                    )}
                  </td>
                  <td data-label="Type">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="casktype"
                        value={editedData.casktype}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      responseData.casktype
                    )}
                  </td>
                  <td data-label="LOA">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="loa"
                        value={editedData.loa}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      formatTwoDecimal(responseData.loa)
                    )}
                  </td>
                  <td data-label="ABV">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="abv"
                        value={editedData.abv}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      `${parseFloat(responseData.abv).toFixed(2)}%`
                    )}
                  </td>
                  <td data-label="OLA/RLA">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="ola_rla"
                        value={editedData.ola_rla}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      responseData.ola_rla
                    )}
                  </td>
                  <td data-label="Price">
                    {editRowId === responseData.cask_id ? (
                      <input
                        type="text"
                        name="price"
                        value={editedData.price}
                        onChange={handleInputChange}
                        // style={{ width: '200px' }}
                      />
                    ) : (
                      `£${new Intl.NumberFormat("en-GB", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(responseData.price)}`
                    )}
                  </td>
                  <td data-label="Actions" className="">
                    {editRowId === responseData.cask_id ? (
                      editAPILoading ? (
                        <div className="loader">Updating...</div> //
                      ) : (
                        <div className="actions-buttons">
                          <button onClick={() => handleSaveClick()}>
                            <img src={SaveImg} alt="Save" />
                          </button>
                          <button onClick={() => setEditRowId(null)}>
                            <img src={CancelImg} alt="Cancel" />
                          </button>
                        </div>
                      )
                    ) : (
                      <div className="action_buttons">
                        <button
                          className="button-edit"
                          onClick={() => handleEditClick(responseData)}
                          style={{
                            backgroundColor: "#33C68D",
                            color: "#fff",
                            padding: "8px 16px",
                            borderRadius: "8px",
                            border: "none",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            gap: "8px",
                            width: "80px",
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="button-edit"
                          onClick={() => handleDeleteClick(responseData)}
                          style={{
                            backgroundColor: "rgb(231 44 44 / 84%)",
                            color: "#fff",
                            padding: "8px 16px",
                            borderRadius: "8px",
                            border: "none",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                            gap: "8px",
                            width: "80px",
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default BorkerPortfolio;
