import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../../../store/sidebar";
import leftIcon from "../../../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../../../assets/images/burgerIconRight.svg";
import { useLocation, Link } from "react-router-dom";
import ProfileCard from "../../../../../components/Shared/ProfileCard/ProfileCard";
import { getUserData } from "../../../../../utils/helperFunctions";
import getEndpoints from "../../../../../../src/config/endpoints";
import axios from "axios";
import View from "./view";
import { useParams } from "react-router-dom";
export default function ViewCask() {
    const openSidebar = useSelector(sidebarStatus);
    const dispatch = useDispatch();
    const profileData = getUserData();
  const { id } = useParams();
  console.log("idddddd",id)
    
  return (
    <div className="mainContainerOuter">
    <main
      id="mainDashboard"
      className={openSidebar ? "main" : "mainContainer"}
    >
      <div className="page-header">
        <div className="pagetitle">
          <div className="sideBarIconMain">
            {openSidebar == true && (
              <button onClick={() => dispatch(hide())} className="leftButton">
                <img src={leftIcon} />
              </button>
            )}
            {openSidebar == false && (
              <button
                onClick={() => dispatch(show())}
                className="rightButton"
              >
                <img src={RightIcon} />
              </button>
            )}
          </div>
          <h1>Private Cask List View</h1>
          <nav className="SubHeading">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/broker/upload_private">
                  {"< Back to Upload List"}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <ProfileCard/>
      </div>

      <section className="section dashboard portfolio">
          {/* <UserPortfolioView /> */}
          <View id={ id} />
      </section>
    </main>
  </div>
  )
}   
