import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../../src/config/endpoints";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { PriceComponent } from "../../../../utils/helperFunctions";
import CancelImg from "../../../../assets/images/close.png";
import SaveImg from "../../../../assets/images/accept.png";
import DeletePromptModal from "../../../../components/Shared/DeletePrompt/deletePromptModal"
const UserPortfolioView = (props) => {
  const [caskList, setCaskList] = useState([]);
  const profileData = JSON.parse(localStorage.getItem("userData"));
  const [editRowId, setEditRowId] = useState(null);
  const [deleteRowId, setDeleteRowId] = useState(null);
  const [editedData, setEditedData] = useState({});
  const [editAPILoading, setEditAPILoading] = useState(false);
  const [deleteAPILoading, setDeleteAPILoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const { memberId } = location.state;
  const formatTwoDecimal = (value: number) => value.toFixed(2);

  const userPortfolioHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("member_id", memberId);
    try {
      const endpoint = getEndpoints().superAdmin.userPortfolio.view;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      setCaskList(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleEditClick = (responseData) => {
    setEditRowId(responseData.cask_id);
    setEditedData(responseData); // Initialize the edited data with current row data
  };
  const handleDeleteClick = (cask) => {
    console.log("cask", cask);
    setDeleteRowId(cask.cask_id);
    // deleteCask(cask.cask_id)
    setShowModal(true);
  };
  const triggerdDelete = async () => {
    await deleteCask(deleteRowId);
    setShowModal(false);
  };
  const triggerdDeleteHide = () => {
    setShowModal(false);
  };
  const deleteCask = async (caskId) => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("member_id", memberId);
    formData.append("cask_id", caskId);
    try {
      const endpoint = getEndpoints().individual.deletecask;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      setCaskList(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const handleSaveClick = () => {
    console.log("editedData", editedData);
    // Call update API with the edited data
    // updateApi(editedData);
   
    updateData();

    // Reset the edit state after saving
  };
  const updateData = async () => {
    try {
      setEditAPILoading(true);
      let formData = new FormData();
      formData.append("user_id", profileData?.user_id);
      formData.append("cask_id", editedData.cask_id);
      formData.append("distillery", editedData.distillery);
      formData.append("cask_type", editedData.casktype);
      formData.append("ays", editedData.ays);
      formData.append("loa", editedData.loa);
      formData.append("ola_rla", editedData.ola_rla);
      formData.append("price", editedData.price.replace(/,/g, ""));
      formData.append("member_id", memberId);

      const endpoint = getEndpoints().individual.editcask;
      const res = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      console.log(res, "responsec");
      if (res.data.success === true) {
        userPortfolioHandler();
        setEditedData({});
        setEditRowId(null);
        setEditAPILoading(false);
      }
      // setResponseDatas(res.data);
    } catch (e) {
      setEditAPILoading(false);
      return e.response || e;
    }
  };

  useEffect(() => {
    userPortfolioHandler();
  }, []);
  return (<>
    <DeletePromptModal
        show={showModal}
        confirmFunc={triggerdDelete}
        closeFunc={triggerdDeleteHide}
        disable={deleteAPILoading}
    />
     <div>
      <table className="user-portfolio-table">
        <thead className="table-head">
          <tr>
            <th scope="col">DISTILLERY</th>
            <th scope="col"> AYS</th>
            <th scope="col"> CASK TYPE</th>
            <th scope="col"> LOA</th>
            <th scope="col"> ABV</th>
            <th scope="col"> OLA/RLA</th>
            <th scope="col"> PRICE</th>
            <th scope="col">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {caskList?.length > 0 &&
            caskList?.map((cask) => (
              <tr key={cask.cask_id} className="portfolioBrkrDataRow">
                <td data-label="Name">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="distillery"
                      value={editedData.distillery}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    cask.distillery
                  )}
                </td>
                <td data-label="Date">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="ays"
                      value={editedData.ays}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    cask.ays
                  )}
                </td>
                <td data-label="Type">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="casktype"
                      value={editedData.casktype}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    cask.casktype
                  )}
                </td>
                <td data-label="LOA">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="loa"
                      value={editedData.loa}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    formatTwoDecimal(cask.loa)
                  )}
                </td>
                <td data-label="ABV">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="abv"
                      value={editedData.abv}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    `${parseFloat(cask.abv).toFixed(2)}%`
                  )}
                </td>
                <td data-label="OLA/RLA">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="ola_rla"
                      value={editedData.ola_rla}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    cask.ola_rla
                  )}
                </td>
                <td data-label="Price">
                  {editRowId === cask.cask_id ? (
                    <input
                      type="text"
                      name="price"
                      value={editedData.price}
                      onChange={handleInputChange}
                      // style={{ width: '200px' }}
                    />
                  ) : (
                    `£${new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cask.price)}`
                  )}
                </td>
                <td data-label="Actions" className="">
                  {editRowId === cask.cask_id ? (
                      editAPILoading ? (
                      
                        <div className="loader">Updating...</div> // 
                      ) : (
                        <div className="actions-buttons">
                          <button onClick={() => handleSaveClick()}>
                            <img src={SaveImg} alt="Save" />
                          </button>
                          <button onClick={() => setEditRowId(null)}>
                            <img src={CancelImg} alt="Cancel" />
                          </button>
                        </div>
                      )
                    )  : (
                    <div className="action_buttons">
                      <button
                        className="button-edit"
                        onClick={() => handleEditClick(cask)}
                        style={{
                          backgroundColor: "#33C68D",
                          color: "#fff",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          border: "none",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          cursor: "pointer",
                          // display: "flex",
                          // alignItems: "center",
                          gap: "8px",
                          width: "80px",
                        }}
                      >
                        Edit
                      </button>
                      <button
                        className="button-edit"
                        onClick={() => handleDeleteClick(cask)}
                        style={{
                          backgroundColor: "rgb(231 44 44 / 84%)",
                          color: "#fff",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          border: "none",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          cursor: "pointer",
                          // display: "flex",
                          // alignItems: "center",
                          gap: "8px",
                          width: "80px",
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  </>
   
  );
};
export default UserPortfolioView;
