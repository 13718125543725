import React, { useState } from "react";
import LOGO from "../../components/brokerAdminSideBar/Menu/images/logo.png";
import Menu from "./Menu";
import { SidebarData, UpcomingData } from "./Menu/data";
import { useSelector } from "react-redux";
import { sidebarStatus } from "../../store/sidebar";
import "./index.scss";
import transaction from "./Menu/images/transaction.svg";
import FeedbackAddModal from "../../pages/Shared/Feedback/FeedbackAddModal/FeedbackAddModal";
import SuccessPrompt from "../Shared/SuccessPrompt/SuccessPrompt";

const BrokerAdminSideBar = () => {
  const [openModal, setOpenModal] = useState(false);
  const openSidebar = useSelector(sidebarStatus);
  const [message, setMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [success, setSuccess] = useState(false);

  const showModal = () => {
    setOpenModal(true);
    console.log("openModal", openModal);
  };
  const handleClose = (actionNo) => {
    switch (actionNo) {
      case 1:
        //cancel
        setOpenModal(false);
        break;
      case 2:
        //submit true
        setOpenSuccess(true);
        setSuccess(true);
        setMessage(
          "Thank you for your feedback. Our team will review it and get back to you shortly."
        );
        setOpenModal(false);
        break;
      case 3:
        //submit error
        setOpenSuccess(true);
        setSuccess(false);
        setMessage("Something went wrong");
        setOpenModal(false);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <SuccessPrompt
        success={success}
        showStatus={openSuccess}
        message={message}
        setOpenSuccess={setOpenSuccess}
      />
      <FeedbackAddModal setDisplay={openModal} handleClose={handleClose} />
      <aside
        className={openSidebar ? "nav-menu-broker" : "nav-menu-broker active"}
      >
        <div className="nav-menu-wrapper">
          <div className="brkr-nav-logo-sec">
            <img className="nav-logo" src={LOGO} alt="logo" />
            <p className="nav-p">BitCask</p>
          </div>
          <div className="sidebar-brkr">
            {SidebarData.map((item, index) => {
              return <Menu item={item} key={index} upcoming={UpcomingData}/>;
            })}
          </div>

          <div className="upcoming">Upcoming Features</div>

          {UpcomingData.map((upcoming, index) => {
            return <Menu key={index} upcoming={upcoming} />;
          })}
        </div>
      </aside>
    </>
  );
};
export default BrokerAdminSideBar;
