import React, { useState, useEffect } from "react";
import Logo from "./images/logo-2.png";
import "./index.scss";
import {
  getToken,
  getUserData,
  setUserData,
} from "../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { useNavigate } from "react-router-dom";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const BrokerSignUp = () => {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [fNameErrors, setFNameErrors] = useState("");
  const [lNameErrors, setLNameErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  const [phone_errors, setPhoneErrors] = useState("");
  const [errors, setErrors] = useState("");
  const [buttonDisable, setButtonDisable] = useState(true);
  let navigate = useNavigate();
  const token = getToken();
  const profileData = getUserData();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user_id = JSON.stringify(profileData.user_id);
    const user_type_id = JSON.stringify(profileData.user_type_id);
    const body = {
      email,
      first_name,
      last_name,
      phone,
      new_password,
      re_password,
      user_id,
      user_type_id,
    };

    const responseData = await authenticationManagementAPIs.createAccountBroker(
      token,
      body
    );
    if (responseData.data.success == true) {
      setUserData(responseData.data.userData);
      navigate("/broker/");
    } else {
      navigate("/broker/broker-create");
    }
  };
  const connectedUserCreate = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);

    try {
      const endpoint = getEndpoints().stripe.createConnectAccount;
      const response = await axios.get(endpoint, formData, {
        headers: { token: profileData.token },
      });

      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const validatePhone = (e) => {
    var pattern = /(^$)|(^[0-9]+$)/;
    if (pattern.test(e.value) == true) {
      setPhone(e.value);
      if (e.value.length < 6 || e.value.length > 16) {
        setPhoneErrors("* Please provide a valid phone number (6-16 Digits)");
        setButtonDisable(true);
      } else {
        setPhoneErrors("");
        setButtonDisable(false);
      }
    }
  };
  const password_match = (e) => {
    if (
      !new_password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
      )
    ) {
      setErrors(
        "* The password must be at least 8 characters long and contain an upper-case letter, a lower-case letter, a number and a special character"
      );
      setButtonDisable(true);
    } else {
      setErrors("");

      if (re_password.length > 0) {
        if (
          !new_password.match(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
          )
        ) {
          setButtonDisable(true);
          setErrors(
            "* The password must be at least 8 characters long and contain an upper-case letter, a lower-case letter, a number and a special character"
          );
          setButtonDisable(true);
        } else {
          setButtonDisable(true);
          if (new_password != re_password) {
            setErrors("Passwords do not match");
            setButtonDisable(true);
          } else {
            setErrors("");
            setButtonDisable(false);
          }
        }
      }
    }
  };
  const validateFName = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setFirstName(e.value);
      if (e.value.length < 3) {
        setFNameErrors("* Please provide a First Name with at least 3 letters");
        setButtonDisable(true);
      } else {
        setButtonDisable(false);
        setFNameErrors("");
      }
    } else {
      setFNameErrors("* Please provide a First Name with at least 3 letters");
      setButtonDisable(true);
    }
  };
  const validateLName = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setLastName(e.value);
      if (e.value.length < 3) {
        setLNameErrors("* Please provide a Last Name with at least 3 letters");
        setButtonDisable(true);
      } else {
        setLNameErrors("");
        setButtonDisable(false);
      }
    } else {
      setLNameErrors("* Please provide a Last Name with at least 3 letters");
      setButtonDisable(true);
    }
  };
  const validateEmail = (e) => {
    setEmail(e.value);
    if (
      e.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailErrors("");
    } else {
      setEmailErrors("* Please provide a valid Email Id");
    }
  };
  const createBtnDisable = () => {
    if (
      email !== "" &&
      first_name !== "" &&
      fNameErrors === "" &&
      lNameErrors === "" &&
      last_name !== "" &&
      phone !== "" &&
      email !== "" &&
      emailErrors === "" &&
      new_password !== "" &&
      re_password !== "" &&
      errors === "" &&
      phone_errors === ""
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  };
  useEffect(() => {
    createBtnDisable();
    // connectedUserCreate();
  }, []);

  return (
    <>
      <main id="create-broker">
        <div className="createBrokerContainer">
          <div className="brokerHeader">
            <img src={Logo} className="createLogo" alt="" />
            <div className="heading">
              <h4>Create an Account</h4>
              <p>Enter your personal details to create account</p>
            </div>
          </div>
          <div className="formBroker">
            <div className="inputs">
              <div className="inputColumn">
                <input
                  type="name"
                  name="first-name"
                  placeholder="First Name"
                  value={first_name}
                  onChange={(e) => validateFName(e.target)}
                  required
                />
                <div className="invalidPhone">{fNameErrors}</div>
                <input
                  type="name"
                  name="last-name"
                  placeholder="Last Name"
                  value={last_name}
                  onChange={(e) => validateLName(e.target)}
                  required
                />
                <div className="invalidPhone">{lNameErrors}</div>
                <PhoneInput
                    country={"sg"}
                    onChange={(phone) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                      style: {
                        padding: "16px 49px",
                        height:"52px"
                      },
                    }}
                    dropdownStyle={{
                      textAlign: "left", // Align dropdown text to the right
                    }}
                    // enableSearch={true}
                  />
                <div className="invalidPhone">{phone_errors}</div>
              </div>
              <div className="inputColumn">
                <input
                  type="email"
                  placeholder="Email Address"
                  required
                  onChange={(e) => validateEmail(e.target)}
                />
                <div className="invalidPassword">{emailErrors}</div>
                <input
                  type="password"
                  placeholder="Choose A New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  onKeyUp={(e) => password_match(e.target)}
                  required
                />
                <div className="invalidPassword"></div>
                <input
                  type="password"
                  placeholder="Re-Enter Password"
                  onChange={(e) => setRePassword(e.target.value)}
                  onKeyUp={(e) => password_match(e.target)}
                  required
                />
                <div className="invalidPassword">{errors}</div>
              </div>
            </div>

            <div className="containerBtn">
              <button
                className={
                  buttonDisable == true ? "btnDisable" : "signUpBtnBroker"
                }
                disabled={buttonDisable}
                onClick={(e) => handleSubmit(e)}
              >
                CREATE ACCOUNT
              </button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BrokerSignUp;
