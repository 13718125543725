import React from "react";
import home from "../Menu/images/home.png";
import box from "../Menu/images/box.svg";
import save from "../Menu/images/save.svg";
import message from "../Menu/images/message.svg";
import transaction from "../Menu/images/transaction.svg";
import wallet from "../Menu/images/wallet.svg";

export const SidebarData = [
  {
    title: "Portfolio",
    path: "/groupadmin/",
    icon: <img src={home} className="nav-img" alt="home" />,
  },
  {
    title: "Marketplace",
    path: "#",
    icon: <img src={box} className="nav-img" alt="home" />,
    iconClosed: <i className="bi bi-chevron-down upload-icon-brkr"></i>,
    iconOpened: <i className="bi bi-chevron-up upload-icon-brkr"></i>,

    subNav: [
      {
        title: "Public",
        path: "/groupadmin/marketplace/public",
        icon: <img src={save} alt="public" />,
      },
      {
        title: "Private",
        path: "/groupadmin/marketplace/private",
        icon: <img src={save} alt="private" />,
      },
    ],
  },
  {
    title: "Request",
    path: "/groupadmin/cask-request",
    icon: <img src={message} className="nav-img" alt="request" />,
  },
  {
    title: "Voting",
    path: "#",
    icon: <img src={box} className="nav-img" alt="home" />,
    iconClosed: <i className="bi bi-chevron-down upload-icon-brkr"></i>,
    iconOpened: <i className="bi bi-chevron-up upload-icon-brkr"></i>,

    subNav: [
      {
        title: "View Poll",
        path: "/groupadmin/view-poll",
        icon: <img src={save} alt="public" />,
      },
      {
        title: "Create Poll",
        path: "/groupadmin/create-poll",
        icon: <img src={save} alt="private" />,
      },
    ],
  },
  {
    title: "Wallet",
    path: "/groupadmin/wallet",
    icon: <img src={wallet} className="nav-img" alt="wallet" />,
  },
  {
    title: "Transaction",
    path: "/groupadmin/transactions",
    icon: <img src={transaction} className="nav-img" alt="transaction" />,
  },
  ,
  {
    title: "Feedback",
    path: "/groupadmin/feedback",
    icon: <img src={transaction} className="nav-img" alt="Feedback" />,
  },
];
