import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../../../src/config/endpoints";
import axios from "axios";

export default function View() {
  const [caskList, setCaskList] = useState([]);

    const profileData = JSON.parse(localStorage.getItem("userData"));
    const formatTwoDecimal = (value: number) => value.toFixed(2);

  const fetchPublicCaskList = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    // formData.append("member_id", memberId);
    try {
      const endpoint = getEndpoints().cask.view;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      setCaskList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
    };
    useEffect(() => {
        fetchPublicCaskList();
      }, []);
  return (
    <div>
      <table className="user-portfolio-table">
        <thead className="table-head">
          <tr>
            <th scope="col">DISTILLERY</th>
            <th scope="col"> AYS</th>
            <th scope="col"> CASK TYPE</th>
            <th scope="col"> LOA</th>
            <th scope="col"> ABV</th>
            <th scope="col"> OLA/RLA</th>
            <th scope="col"> PRICE</th>
            {/* <th scope="col">ACTIONS</th> */}
          </tr>
        </thead>
        <tbody className="table-body">
          {caskList?.length > 0 &&
            caskList?.map((cask) => (
              <tr key={cask.cask_id} className="portfolioBrkrDataRow">
                <td data-label="Name">
                  
                    {cask.distillery}
                 
                </td>
                <td data-label="Date">
                  { cask.ays}
                </td>
                <td data-label="Type">
                  { cask.casktype}
                </td>
                <td data-label="LOA">
                  {formatTwoDecimal(cask.loa)}
                </td>
                <td data-label="ABV">
                  {`${parseFloat(cask.abv).toFixed(2)}%`}
                </td>
                <td data-label="OLA/RLA">
                  { cask.ola_rla}
                </td>
                <td data-label="Price">
                  { `£${new Intl.NumberFormat("en-GB", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(cask.price)}`}
                </td>
               
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
