import React, { useState } from "react";
import logo from "./Menu/images/logo.png";
import Menu from "./Menu";
import { SidebarData, UpcomingData } from "./Menu/data";
import { useSelector } from "react-redux";
import { sidebarStatus } from "../../store/sidebar";
import "./index.scss";
import transaction from "./Menu/images/transaction.svg";
import FeedbackAddModal from "../../pages/Shared/Feedback/FeedbackAddModal/FeedbackAddModal";
import SuccessPrompt from "../Shared/SuccessPrompt/SuccessPrompt";

const IndividualSideBar = () => {
  const openSidebar = useSelector(sidebarStatus);
  const [openModal, setOpenModal] = useState(false);
  const [message, setMessage] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);
  const [success, setSuccess] = useState(false);

  const showModal = () => {
    setOpenModal(true);
    console.log("openModal", openModal);
  };
  const handleClose = (actionNo) => {
    switch (actionNo) {
      case 1:
        //cancel
        setOpenModal(false);
        break;
      case 2:
        //submit true
        setOpenSuccess(true);
        setSuccess(true);
        setMessage("Thank you for your feedback. Our team will review it and get back to you shortly.");
        setOpenModal(false);
        break;
      case 3:
        //submit error
        setOpenSuccess(true);
        setSuccess(false);
        setMessage("Something went wrong");
        setOpenModal(false);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <SuccessPrompt
        success={success}
        showStatus={openSuccess}
        message={message}
        setOpenSuccess={setOpenSuccess}
      />
      <FeedbackAddModal setDisplay={openModal} handleClose={handleClose} />
      <aside
        className={openSidebar ? "nav-menu-broker" : "nav-menu-broker active"}
      >
        <div className="nav-menu-wrapper">
          <div className="brkr-nav-logo-sec">
            <img className="nav-logo" src={logo} alt="logo" />
            <p className="nav-p">BitCask</p>
          </div>

          <div className="sidebar-brkr">
            {SidebarData.map((item, index) => {
              return <Menu item={item} key={index} upcoming={UpcomingData} />;
            })}
            <div className="sidebar-brkr">
              <div className="sidebar-brkr-2">
                <div className="sideBarLinkBrkr">
                  <div className="nav-link-brkr" onClick={showModal}>
                    <img
                      src={transaction}
                      className="nav-img"
                      alt="marketplace"
                    />
                    <span className="sideBarLabelBrkr">Feedback</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="upcoming">Upcoming Features</div>

            {UpcomingData.map((upcoming, index) => {
              return <Menu key={index} upcoming={upcoming} />;
            })}
          </div>
        </div>
      </aside>
    </>
  );
};
export default IndividualSideBar;
