import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../../../store/sidebar";
import leftIcon from "../../../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../../../assets/images/burgerIconRight.svg";
import { useLocation, Link } from "react-router-dom";
import ProfileCard from "../../../../../components/Shared/ProfileCard/ProfileCard";
import { getUserData } from "../../../../../utils/helperFunctions";
import getEndpoints from "../../../../../../src/config/endpoints";
import axios from "axios";
import View from "./view";
export default function ViewCask() {
    const openSidebar = useSelector(sidebarStatus);
    const dispatch = useDispatch();
    const profileData = getUserData();
    const [modalData, setModalData] = useState({});
    
    const handleViewCask = async() => {
        try {
          let formData = new FormData();
          formData.append("user_id", profileData.user_id);
          const endpoint = getEndpoints().cask.view;
          const response = await axios.post(endpoint, formData, {
            headers: { token: profileData.token },
          });
    
          if (response.data.success == "True") {
            
          } else {
            setModalData({ success: false, modalShow: true });
          }
          return response;
        } catch (e) {
          setModalData({ success: false, modalShow: true });
          return e.response || e;
        }
      }
  return (
    <div className="mainContainerOuter">
    <main
      id="mainDashboard"
      className={openSidebar ? "main" : "mainContainer"}
    >
      <div className="page-header">
        <div className="pagetitle">
          <div className="sideBarIconMain">
            {openSidebar == true && (
              <button onClick={() => dispatch(hide())} className="leftButton">
                <img src={leftIcon} />
              </button>
            )}
            {openSidebar == false && (
              <button
                onClick={() => dispatch(show())}
                className="rightButton"
              >
                <img src={RightIcon} />
              </button>
            )}
          </div>
          <h1>Public Cask List View</h1>
          <nav className="SubHeading">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/broker/upload/public">
                  {"< Back to Upload List"}
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <ProfileCard/>
      </div>

      <section className="section dashboard portfolio">
          {/* <UserPortfolioView /> */}
          <View />
      </section>
    </main>
  </div>
  )
}   
