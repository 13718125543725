import React, { useState, useEffect } from "react";
import Logo from "./images/logo-2.png";
import "./index.scss";
import {
  getToken,
  getUserData,
  setUserData,
} from "../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
const GMUserSignUp = () => {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [re_password, setRePassword] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [postCode, setPostCode] = useState("");
  const [dob, setDOB] = useState("");
  const [phone_errors, setPhoneErrors] = useState("");
  const [fNameErrors, setFNameErrors] = useState("");
  const [lNameErrors, setLNameErrors] = useState("");
  const [addressErrors, setAddressErrors] = useState("");
  const [dobErrors, setdobErrors] = useState("Please enter Date of Birth");
  const [cityErrors, setCityErrors] = useState("");
  const [countryErrors, setCountryErrors] = useState("");
  const [postCodeErrors, setPostCodeErrors] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [errors, setErrors] = useState("");
  const [nextButtonDisable, setNextButtonDisable] = useState(true);
  const [createButtonDisable, setCreateButtonDisable] = useState(true);
  const [nextScreen, setNextScreen] = useState(false);
  const [idProof, setIdProof] = useState([]);
  const [addressProof, setAddressProof] = useState([]);
  const [idProofName, setIdProofName] = useState("");
  const [addressProofName, setAddressProofName] = useState("");
  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputAddress = React.useRef(null);
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    if (
      address !== "" &&
      city !== "" &&
      country !== "" &&
      idProof !== "" &&
      addressProof !== "" &&
      dob !== "" &&
      postCode !== ""
    ) {
      e.preventDefault();
      const token = getToken();
      const userData = getUserData();
      const user_id = JSON.stringify(userData.user_id);
      const user_type_id = JSON.stringify(userData.user_type_id);
      const formData = new FormData();
      formData.append("email", email);
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("phone", phone);
      formData.append("new_password", new_password);
      formData.append("re_password", re_password);
      formData.append("user_id", user_id);
      formData.append("user_type_id", user_type_id);
      formData.append("address", address);
      formData.append("city", city);
      formData.append("country", country);
      formData.append("idProof", idProof);
      formData.append("addressProof", addressProof);
      formData.append("dob", dob);
      formData.append("postcode", postCode);

      const responseData =
        await authenticationManagementAPIs.createAccountGroupMember(
          token,
          formData
        );
      if (responseData.data.success == true) {
        setUserData(responseData.data.userData);
        navigate("/groupmember/");
      } else {
        navigate("/groupmember/groupmember-create");
      }
    } else {
      setCreateButtonDisable(true);
    }
  };
  const validatePhone = (e) => {
    var pattern = /^\+?(?:[\d]*)$/;
    if (pattern.test(e.value) == true) {
      setPhone(e.value);
      if (e.value.length < 6 || e.value.length > 16) {
        setPhoneErrors("* Please provide a valid phone number (6-16 Digits)");
        setNextButtonDisable(true);
      } else {
        setPhoneErrors("");
        setNextButtonDisable(false);
      }
    } else {
      setPhoneErrors("");
      setNextButtonDisable(false);
    }
  };
  const password_match = (e) => {
    if (
      !new_password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
      )
    ) {
      setErrors(
        "* The password must be at least 8 characters long and contain an upper-case letter, a lower-case letter, a number and a special character"
      );
      setNextButtonDisable(true);
    } else {
      setErrors("");
      setNextButtonDisable(false);
      if (re_password.length > 0) {
        if (
          !new_password.match(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
          )
        ) {
          setErrors(
            "* The password must be at least 8 characters long and contain an upper-case letter, a lower-case letter, a number and a special character"
          );
          setNextButtonDisable(true);
        } else {
          if (new_password != re_password) {
            setErrors("Passwords do not match");
            setNextButtonDisable(true);
          } else {
            setErrors("");
            setNextButtonDisable(false);
          }
        }
      }
    }
  };
  const validateFName = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setFirstName(e.value);
      if (e.value.length < 3) {
        setFNameErrors("* Please provide a First Name with at least 3 letters");
        setNextButtonDisable(true);
      } else {
        setNextButtonDisable(false);
        setFNameErrors("");
      }
    } else {
      setFNameErrors("* Please provide a First Name with at least 3 letters");
      setNextButtonDisable(true);
    }
  };

  const validateLName = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setLastName(e.value);
      if (e.value.length < 3) {
        setLNameErrors("* Please provide a Last Name with at least 3 letters");
        setNextButtonDisable(true);
      } else {
        setLNameErrors("");
        setNextButtonDisable(false);
      }
    } else {
      setLNameErrors("* Please provide a Last Name with at least 3 letters");
      setNextButtonDisable(true);
    }
  };
  const validateAddress = (e) => {
    // var pattern = /(^$)|(^[.0-9a-zA-Z\s,-]+$)/;
    if (e.value) {
      setAddress(e.value);
      setAddressErrors("");
    } else {
      setAddressErrors("Please provide valid Address");
    }
  };
  const validateCity = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setCity(e.value);
      setCityErrors("");
    } else {
      setCityErrors("Please provide valid City name");
    }
  };
  const validateCountry = (e) => {
    var pattern = /(^$)|(^[a-zA-Z]+$)/;
    if (pattern.test(e.value) == true) {
      setCountry(e.value);
      setCountryErrors("");
      setNextButtonDisable(false);
    } else {
      setCountryErrors("Please provide valid Country name");
      setNextButtonDisable(true);
    }
  };
  const validateDOB = (e) => {
    let newDate = new Date();
    const selectedDate = new Date(e.target.value);
    var birthDate = new Date(e.target.value);
    var age_now = newDate.getFullYear() - selectedDate.getFullYear();
    var m = newDate.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && newDate.getDate() < birthDate.getDate())) {
      age_now--;
    }
    if (age_now >= 18) {
      setDOB(e.target.value);
      setdobErrors("");
    } else {
      setNextButtonDisable(true);
      setdobErrors("only 18+ users can sign up");
    }
  };
  const validatePostCode = (e) => {
    var pattern = /(^$)|(^[.0-9a-zA-Z\s,-]+$)/;
    if (pattern.test(e.value) == true) {
      setPostCode(e.value);
      setPostCodeErrors("");
      setNextButtonDisable(false);
    } else {
      setPostCodeErrors("Please provide valid input");
      setNextButtonDisable(true);
    }
  };
  const idProofUpload = (e) => {
    setIdProof(e.target.files[0]);
    setIdProofName(e.target.files[0].name);
  };
  const addressProofUpload = (e) => {
    setAddressProof(e.target.files[0]);
    setAddressProofName(e.target.files[0].name);
  };
  const validateEmail = (e) => {
    setEmail(e.value);
    if (
      e.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailErrors("");
    } else {
      setEmailErrors("* Please provide a valid Email Id");
    }
  };
  const validateNextClick = () => {
    if (
      phone !== "" &&
      first_name !== "" &&
      last_name !== "" &&
      new_password !== "" &&
      re_password !== "" &&
      email !== "" &&
      phone_errors == "" &&
      fNameErrors == "" &&
      lNameErrors == "" &&
      errors == "" &&
      emailErrors == ""
    ) {
      setNextButtonDisable(false);
    } else {
      setNextButtonDisable(true);
      setNextScreen(false);
    }
  };
  const validateNextScreen = () => {
    if (nextButtonDisable !== true) {
      setNextScreen(true);
    } else {
      setNextScreen(false);
    }
  };
  const createBtnDisable = () => {
    if (
      address !== "" &&
      city !== "" &&
      country !== "" &&
      idProof !== "" &&
      addressProof !== "" &&
      dob !== "" &&
      postCode !== "" &&
      addressErrors == "" &&
      cityErrors == "" &&
      countryErrors == "" &&
      postCodeErrors == "" &&
      dobErrors == "" &&
      addressProofName !== "" &&
      idProofName !== ""
    ) {
      setCreateButtonDisable(false);
    } else {
      setCreateButtonDisable(true);
    }
  };
  useEffect(() => {
    validateNextClick();
    createBtnDisable();
  });
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleAddressProofClick = (event) => {
    hiddenFileInputAddress.current.click();
  };

  return (
    <>
      <main id="create-group-admin">
        <div className="createGMContainer">
          <div className="GAHeader">
            <img src={Logo} className="createLogo" alt="" />
            <div className="heading">
              <h4 className="headingTitle">Create an Account</h4>
              <div
                className={nextScreen == false ? "progressBar" : "progressBar2"}
              >
                <div className="barSignUp">
                  <div className="ball1">
                    <div className="ballActive"></div>
                  </div>
                  <div className="ball">
                    <div className="ballActive2"></div>
                  </div>
                </div>
                <div className="names">
                  <a href="" className="">
                    Login Details
                  </a>
                  <a href="">Personal Details</a>
                </div>
              </div>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className={nextScreen == false ? "" : "nextScreen"}>
              <div className="inputs">
                <div className="inputColumn">
                  <input
                    type="name"
                    name="first-name"
                    placeholder="First Name"
                    value={first_name}
                    onChange={(e) => validateFName(e.target)}
                    required
                  />
                  <div className="invalidPhone">{fNameErrors}</div>
                  <input
                    type="name"
                    name="last-name"
                    placeholder="Last Name"
                    value={last_name}
                    onChange={(e) => validateLName(e.target)}
                    required
                  />
                  <div className="invalidPhone">{lNameErrors}</div>
                  <input
                    className={errors ? "focusError" : ""}
                    id="phone"
                    type="text"
                    placeholder="Phone"
                    value={phone}
                    required
                    onChange={(e) => validatePhone(e.target)}
                  />
                  <div className="invalidPhone">{phone_errors}</div>
                </div>
                <div className="inputColumn">
                  <input
                    type="email"
                    placeholder="Email Address"
                    required
                    onChange={(e) => validateEmail(e.target)}
                  />
                  <div className="invalidPhone">{emailErrors}</div>
                  <input
                    type="password"
                    placeholder="Choose A New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                    onKeyUp={(e) => password_match(e.target)}
                    required
                  />
                  <div className=" invalidPhone invalid"> </div>
                  <input
                    type="password"
                    placeholder="Re-Enter Password"
                    onChange={(e) => setRePassword(e.target.value)}
                    onKeyUp={(e) => password_match(e.target)}
                    required
                  />
                  <div className="invalidPassword">{errors}</div>
                </div>
              </div>
            </div>
            <div className={nextScreen == true ? "" : "nextScreen"}>
              <div className="inputs">
                <div className="input-column">
                  <input
                    type="text"
                    name="street"
                    placeholder="Address"
                    value={address}
                    onChange={(e) => validateAddress(e.target)}
                    required
                  />
                  <div className=" invalidPhone invalid">{addressErrors}</div>
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={city}
                    onChange={(e) => validateCity(e.target)}
                    required
                  />
                  <div className=" invalidPhone invalid">{cityErrors}</div>
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={country}
                    onChange={(e) => validateCountry(e.target)}
                    required
                  />
                  <div className="invalidPhone invalid">{countryErrors}</div>
                  <input
                    type="address"
                    name="postcode"
                    placeholder="Postcode"
                    value={postCode}
                    onChange={(e) => validatePostCode(e.target)}
                    required
                  />
                  <div className="invalidPhone invalid">{postCodeErrors}</div>
                </div>
                <div className="input-column">
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    value={dob}
                    onChange={(e) => validateDOB(e)}
                    placeholder="Date of Birth"
                  />
                  <div className="invalidPassword">{dobErrors}</div>
                  <div className="files-container">
                    <p>Please Submit KYC Documents</p>
                    <div className="files">
                      <InputGroup className="mb-6">
                        <InputGroup.Text
                          id="basic-addon2"
                          className="inputGroupLabel"
                        >
                          Proof of ID
                        </InputGroup.Text>
                        <Form.Control
                          className="inputGroupFile"
                          placeholder={idProofName}
                          aria-label=""
                          aria-describedby="basic-addon2"
                          onClick={handleClick}
                          readOnly
                        />
                        <input
                          type="file"
                          name="idProof"
                          className="inputGroupFile"
                          onChange={(e) => idProofUpload(e)}
                          accept=".png,.jpeg,.pdf"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </InputGroup>
                      <InputGroup className="mb-6">
                        <InputGroup.Text
                          id="basic-addon2"
                          className="inputGroupLabel"
                        >
                          Proof of Address
                        </InputGroup.Text>
                        <Form.Control
                          className="inputGroupFile"
                          placeholder={addressProofName}
                          aria-label=""
                          aria-describedby="basic-addon2"
                          onClick={handleAddressProofClick}
                          readOnly
                        />
                        <input
                          type="file"
                          name="idProof"
                          className="inputGroupFile"
                          onChange={(e) => addressProofUpload(e)}
                          accept=".png,.jpeg,.pdf"
                          ref={hiddenFileInputAddress}
                          style={{ display: "none" }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerBtn">
                <button
                  type="submit"
                  className={
                    createButtonDisable == true ? "buttonDisable" : "signUpBtn"
                  }
                  disabled={createButtonDisable}
                >
                  CREATE ACCOUNT
                </button>
              </div>
            </div>
            <div
              className={nextScreen == false ? "containerBtn" : "nextScreen"}
            >
              <button
                className={
                  nextButtonDisable == true ? "buttonDisable" : "signUpBtn"
                }
                disabled={nextButtonDisable}
                onClick={validateNextScreen}
              >
                NEXT
              </button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default GMUserSignUp;
