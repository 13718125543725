import React, { useState } from "react";
import Portfolio from "../IndividualAdminPortfolio";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";
import "./index.scss";
const IndividualAdminDashboard = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [totalAmount,setTotalAmount] = useState("")

  return (
    <>
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="page-header">
            <div className="sideBarIconMain">
              {openSidebar == true && (
                <button onClick={() => dispatch(hide())} className="leftButton">
                  <img src={leftIcon} />
                </button>
              )}
              {openSidebar == false && (
                <button
                  onClick={() => dispatch(show())}
                  className="rightButton"
                >
                  <img src={RightIcon} />
                </button>
              )}
            </div>
            <div className="pagetitle">
              <h1>Portfolio</h1>
            </div>

            <ProfileCard />
          </div>

          <section className="section dashboard">
            <div className="outer_div">
              <div className="inner_div">Total Amount : £{totalAmount}</div>
            </div>
            <Portfolio setTotalAmount={setTotalAmount} />
          </section>
        </main>
      </div>
    </>
  );
};

export default IndividualAdminDashboard;
