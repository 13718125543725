import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import PanelRightImage from "../../../Broker/BrokerAdminDashboard/resources/uploadNew.png";
import UploadIcon from "../../../Broker/BrokerAdminDashboard/resources/uploadIcon.svg";
import { useParams } from "react-router-dom";
import ProfileCard from "../../../../components/Shared/ProfileCard/ProfileCard";
import "./index.scss";
import getEndpoints from "../../../../../src/config/endpoints";
import axios from "axios";
import ModalBox from "../ModalBox/ModalBox";
import { getUserData } from "../../../../utils/helperFunctions";
import leftIcon from "../../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../../store/sidebar";
import { useNavigate } from "react-router-dom";

const BrokerUploadListPublic = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const { pagetype } = useParams();
  const profileData = getUserData();
  let navigate = useNavigate();
  const page = pagetype == "stock" ? "Stock" : "Public";
  const SubHeading =
    pagetype == "stock"
      ? "You are updating your own Stock List. Only you will be able to view these casks in your Portfolio. Please make sure the excel file you upload has the following headers:"
      : "You are updating your Public MarketPlace. All Bitcask Customers with access to the dashboard will be able to view and purchase these casks. Please make sure the excel file you upload has the following headers:";
  const hiddenFileInput1 = React.useRef(null);
  const triggerUpload = () => {
    hiddenFileInput1.current.click();
  };
  const triggerClose = () => {
    setModalData({});
  };
  const uploadType = pagetype == "stock" ? "Stock" : "Public";
  const fileUpload = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("files", fileUploaded);
    formData.append("user_id", profileData.user_id);
    formData.append(
      "cask_name",
      pagetype == "stock" ? "stock_cask" : "public_cask"
    );

    try {
      const endpoint = getEndpoints().cask.upload;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      if (response.data.success == "True") {
        setModalData({ success: true, modalShow: true });
      } else {
        setModalData({ success: false, modalShow: true });
      }
      return response;
    } catch (e) {
      setModalData({ success: false, modalShow: true });
      return e.response || e;
    }
  };
  const handleViewCask = () => {
    console.log('triggered')
    navigate('/broker/upload/public/cask')
  }

  return (
    <>
      {Object.keys(modalData).length !== 0 && (
        <ModalBox
          success={modalData.success}
          modalShow={modalData.modalShow}
          dataFunction={triggerUpload}
          closeModal={triggerClose}
          uploadType={uploadType}
        />
      )}
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="row">
            <div className="pagetitle">
              <div className="sideBarIconMain">
                {openSidebar == true && (
                  <button
                    onClick={() => dispatch(hide())}
                    className="leftButton"
                  >
                    <img src={leftIcon} />
                  </button>
                )}
                {openSidebar == false && (
                  <button
                    onClick={() => dispatch(show())}
                    className="rightButton"
                  >
                    <img src={RightIcon} />
                  </button>
                )}
              </div>
              <h1 className="mainHeadingSub">Upload List</h1>
              <div className="SubHeading">Upload {page} Cask List</div>
            </div>
            <ProfileCard />
          </div>
          <div className="UploadSection">
            <div className="UploadSectionLeft">
              {/* <div className="UploadText">Upload</div> */}
              {/* <div>
                <form>
                  <input
                    type="file"
                    accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    ref={hiddenFileInput}
                    onChange={fileUpload}
                  />
                  <Button className="UploadButton" onClick={triggerUpload}>
                    <div className="uploadIcon">
                      <img src={UploadIcon} />
                    </div>
                    <div className="uploadIconText">
                      Upload {page} Cask List
                    </div>
                  </Button>
                </form>
                <div className="sectionCaptionOne">{page} Cask List</div>
                <div className="sectionCaptionSecond">{SubHeading}</div>
                <div className="sectionCaptionThree">
                  Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price
                </div>
              </div> */}
              <div className="PrivateUploadSectionTop">
                <form>
                  <input
                    type="file"
                    accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    ref={hiddenFileInput1}
                    onChange={fileUpload}
                  />
                  <Button
                    className="PrivateUploadButton"
                    onClick={triggerUpload}
                  >
                    <div className="uploadIconPublic">
                      <img src={UploadIcon} />
                    </div>
                    <div className="uploadIconText">
                      Upload Public Cask List
                    </div>
                  </Button>
                </form>
                <div className="PrivatesectionCaptionOne">Upload Casks</div>
                <div className="PrivatesectionCaptionSecond">
                  You are updating your Public MarketPlace. All Bitcask
                  Customers with access to the dashboard will be able to view
                  and purchase these casks. Please make sure the excel file you
                  upload has the following headers:
                </div>
                <div className="PrivatesectionCaptionThree">
                  Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price
                </div>
              </div>
              <div className="borderBottom">
                --------------------------------------------------------
              </div>
              <div className="PrivateUploadSectionBottom">
                <form>
                  
                  <Button
                    className="PrivateUploadButton"
                    onClick={()=>handleViewCask()}
                  >
                    <div className="uploadIconPublic">
                      {/* <img src={UploadIcon} /> */}
                    </div>
                    <div className="uploadIconText">View Public Cask List</div>
                  </Button>
                </form>
                <div className="PrivatesectionCaptionOne">View Casks</div>
                <div className="PrivatesectionCaptionSecond">
                  Click if you want to view the uploaded casks.
                </div>
                {/* <div className="PrivatesectionCaptionThree">
                  Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price
                </div> */}
              </div>
            </div>
            <div className="UploadSectionRight">
              <img src={PanelRightImage} />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default BrokerUploadListPublic;
