import React, { useState, useEffect } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";
import FeedbackAddModal from "./FeedbackAddModal/FeedbackAddModal";
import getEndpoints from "../../../config/endpoints";
import { getUserData } from "../../../utils/helperFunctions";
import axios from "axios";
import FeedbackView from "./FeedbackViewModal";
const FeedbackList = (props) => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState({})
  const location = useLocation();
  // const { caskid } = location.state;
  const profileData = getUserData();

  const fetchFeedback = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().feedback.view;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      // setWalletData(response.data[0]);
      setFeedbackList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const handleModalAction = (actionNumber) => {
    switch (actionNumber) {
      case 1:
        fetchFeedback();
        props.modalStatus(false);
        break;
        case 2:
        setShowViewModal(false);
        setFeedbackData({})
          break;
      default:
        break;
    }
  };
  const handleFeedbackView = async(data) => {
    await setFeedbackData(data)
    setShowViewModal(true)
    
  }
  useEffect(() => {
    fetchFeedback();
  }, []);

  return (
    <>
      <FeedbackAddModal
        setDisplay={props.modalStatus}
        // caskData={caskid}
        hideTriggerFunc={props.hideTrigger}
        handleModalAction={handleModalAction}
      />
      <FeedbackView
        setDisplay={showViewModal}
        feedback={feedbackData}
        // hideTriggerFunc={props.hideTrigger}
        handleModalAction={handleModalAction}
      />

      <div className="feedbackListMain">
        <table className="feedbackTable">
          <thead>
            <tr>
              <th style={{ width: "5%" }} >Sl No</th>
              <th>Feedback</th>
              <th style={{ width: "20%" }}>ACTION</th>
            </tr>
          </thead>
          <tbody className="">
            {feedbackList &&
              feedbackList?.map((feedback, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{feedback.feedback}</td>

                  <td style={{userSelect: 'none', cursor: 'default'}}>
                    <div className="buttonRowFeedbackList">
                      <button
                        onClick={() => {
                          handleFeedbackView(feedback);
                        }}
                        className="viewGreenbtn"
                      >
                        VIEW
                      </button>

                      {/* <button
                        value={feedback.feedback_id}
                        // onClick={(e) => {
                        //   deleteTrigger(e.target.value);
                        // }}
                        className="deleteRedbtn"
                      >
                        DELETE
                      </button> */}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default FeedbackList;
