import React, { useEffect,useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./components/Auth";
import Login from "./pages/Shared/Login";
import BrokerRoutes from "./config/brokerRoutes";
import GroupAdminRoutes from "./config/grpAdminRoutes";
import GroupMemberRoutes from "./config/grpMemberRoutes";
import ForgotPassword from "./pages/Shared/ForgotPassword/forgotPassword";
import ResetPassword from "./pages/Shared/ForgotPassword/resetPassword";
import GMUserSignUp from "./pages/GroupMember/GroupMemberSignUp";
import GAUserSignUp from "./pages/GroupAdmin/GroupAdminSignUp";
import BrokerSignUp from "./pages/Broker/BrokerSignUp";
import IndividualAdminRoutes from "./config/individualAdminRoutes";
import IAUserSignUp from "./pages/IndividualAdmin/IndividualAdminSignUp";
import { getUserData } from "./utils/helperFunctions";
import RouteAuth from "./components/Auth/routeAuth";
import RouteAuthGA from "./components/Auth/routeAuthGA";
import RouteAuthMA from "./components/Auth/routeAuthMA";
import RouteAuthIA from "./components/Auth/routeAuthIA";

const App = () => {
  let profileData = getUserData();
  const [userData, setUserData] = useState(null);
  console.log("profileData",userData)
  useEffect(() => {
    let profileData = getUserData();
  },[profileData]);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login setUserData={setUserData} />} />
        {userData != null || profileData != null? (
          <>
            <Route
              exact
              path="/broker/*"
              element={
                <RouteAuth userType={profileData.user_type_id}>
                  <BrokerRoutes />
                </RouteAuth>
              }
            />

            <Route
              exact
              path="/groupadmin/*"
              element={
                <RouteAuthGA userType={profileData.user_type_id}>
                  <GroupAdminRoutes />
                </RouteAuthGA>
              }
            />
            <Route
              exact
              path="/groupmember/*"
              element={
                <RouteAuthMA userType={profileData.user_type_id}>
                  <GroupMemberRoutes />
                </RouteAuthMA>
              }
            />
            <Route
              exact
              path="/individualadmin/*"
              element={
                <RouteAuthIA userType={profileData.user_type_id}>
                  <IndividualAdminRoutes />
                </RouteAuthIA>
              }
            />
            <Route
              path="groupadmin/groupadmin-create"
              element={
                <Auth>
                  <RouteAuthGA userType={profileData.user_type_id}>
                    <GAUserSignUp />
                  </RouteAuthGA>
                </Auth>
              }
            />
            <Route
              exact
              path="broker/broker-create"
              element={
                <Auth>
                  <BrokerSignUp />
                </Auth>
              }
            />
            <Route
              exact
              path="individualadmin/individualadmin-create"
              element={
                <Auth>
                  <RouteAuthIA userType={profileData.user_type_id}>
                    {" "}
                    <IAUserSignUp />
                  </RouteAuthIA>
                </Auth>
              }
            />
          </>
        ) : (
          <></>
        )}

        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/reset-password" element={<ResetPassword />} />
        <Route
          path="groupmember/groupmember-create"
          element={
            <Auth>
              <GMUserSignUp />
            </Auth>
          }
        />

        <Route exact path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
