import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import PanelRightImage from "../../../Broker/BrokerAdminDashboard/resources/uploadNew.png";
import UploadIcon from "../../../Broker/BrokerAdminDashboard/resources/uploadIcon.svg";
import ProfileCard from "../../../../components/Shared/ProfileCard/ProfileCard";
import "./index.scss";
import getEndpoints from "../../../../../src/config/endpoints";
import axios from "axios";
import { Typeahead } from "react-bootstrap-typeahead";
import ModalBox from "../ModalBox/ModalBox";
import { getUserData } from "../../../../utils/helperFunctions";
import leftIcon from "../../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../../store/sidebar";
import { useNavigate } from "react-router-dom";

const BrokerUploadPrivate = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const [currentSelection, setCurrentSelection] = useState("");
  const [userType, setUserType] = useState([]);
  const [typeEmailList, setTypeEmailList] = useState([]);
  const [selected, setSelected] = useState([]);
  const profileData = getUserData();
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);
  let navigate = useNavigate();
  const triggerUploadIndividual = () => {
    if (selected[0]) {
      hiddenFileInput1.current.click();
      setCurrentSelection("Individual");
    }
  };
  const triggerUploadPortfolio = () => {
    if (selected[0]) {
      hiddenFileInput2.current.click();
      setCurrentSelection("Portfolio");
    }
  };
  const triggerClose = () => {
    setModalData({});
  };
  const typeEmailHandler = async (event) => {
    const user_type_id = event.target.value;
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", user_type_id);
    try {
      const endpoint = getEndpoints().users.type_users_emails;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setTypeEmailList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const fileUploadIndividual = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("files", fileUploaded);
    formData.append("user_id", profileData.user_id);
    formData.append("cask_name", "individual_cask");
    formData.append("id", selected[0].user_id);
    try {
      const endpoint = getEndpoints().cask.upload;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == "True") {
        setModalData({ success: true, modalShow: true });
      } else {
        setModalData({ success: false, modalShow: true });
      }
      return response;
    } catch (e) {
      setModalData({ success: false, modalShow: true });
      return e.response || e;
    }
  };
  const fileUploadPortfolio = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("files", fileUploaded);
    formData.append("user_id", profileData.user_id);
    formData.append("cask_name", "portfolio_cask");
    formData.append("id", selected[0].user_id);
    try {
      const endpoint = getEndpoints().cask.upload;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == "True") {
        setModalData({ success: true, modalShow: true });
      } else {
        setModalData({ success: false, modalShow: true });
      }
      return response;
    } catch (e) {
      setModalData({ success: false, modalShow: true });
      return e.response || e;
    }
  };
  const triggerReUpload = () => {
    if (currentSelection == "Individual") {
      triggerUploadIndividual();
    } else {
      triggerUploadPortfolio();
    }
  };
  const userTypeHandle = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", profileData.user_type_id);
    try {
      const endpoint = getEndpoints().users.user_types;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setUserType(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const handleViewCask = () => {
    console.log("triggered",selected);
    if (selected.length > 0)  {
      let id = selected[0].user_id;
      navigate(`/broker/upload/private/cask/${id}`);
    } else {
      let id = "all";
      navigate(`/broker/upload/private/cask/${id}`);
    }
  };

  useEffect(() => {
    userTypeHandle();
  }, []);
  return (
    <>
      {Object.keys(modalData).length !== 0 && (
        <ModalBox
          success={modalData.success}
          modalShow={modalData.modalShow}
          dataFunction={triggerReUpload}
          closeModal={triggerClose}
          uploadType={"Private"}
        />
      )}
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="page-header">
            <div className="pagetitle">
              <div className="sideBarIconMain">
                {openSidebar == true && (
                  <button
                    onClick={() => dispatch(hide())}
                    className="leftButton"
                  >
                    <img src={leftIcon} />
                  </button>
                )}
                {openSidebar == false && (
                  <button
                    onClick={() => dispatch(show())}
                    className="rightButton"
                  >
                    <img src={RightIcon} />
                  </button>
                )}
              </div>
              <h1 className="mainHeadingSub">Upload List</h1>
              <div className="PrivateSubHeading">Upload Private Cask List</div>
            </div>
            <ProfileCard />
          </div>
          <div className="PrivateUploadSection">
            <div>
              <div className="input-group">
                <div className="PrivateuserType">
                  <select
                    className="form-select privateDrpdwn"
                    id="sel1"
                    name="sellist1"
                    onChange={typeEmailHandler}
                  >
                    <option value="" selected disabled hidden>
                      User Type
                    </option>
                    {userType && userType.length>0 &&
                      userType.map((type) => {
                        if (
                          type.user_type_id !== 3 &&
                          type.user_type_id !== 5
                        ) {
                          return;
                        }
                        return (
                          <option
                            value={type.user_type_id}
                            key={type.user_type_id}
                          >
                            {type.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className="PrivateuserEmail">
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="email"
                    onChange={setSelected}
                    options={typeEmailList}
                    placeholder="User's Email"
                    selected={selected}
                  />
                </div>
              </div>
            </div>
            <div className="PrivateUploadSectionLeft">
              <div className="PrivateUploadSectionTop">
                <form>
                  <input
                    type="file"
                    accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    ref={hiddenFileInput2}
                    onChange={fileUploadPortfolio}
                  />
                  <Button
                    className="PrivateUploadButton"
                    onClick={triggerUploadPortfolio}
                  >
                    <div className="PrivateuploadIcon">
                      <img src={UploadIcon} />
                    </div>
                    <div className="uploadIconText"> Upload Portfolio</div>
                  </Button>
                </form>
                <div className="PrivatesectionCaptionOne">Casks</div>
                <div className="PrivatesectionCaptionSecond">
                  Click if you uploading a <b>private Cask Portfolio</b> to a
                  specific user.Please make sure the excel file you upload has
                  the following headers:
                </div>
                <div className="PrivatesectionCaptionThree">
                  Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price
                </div>
              </div>
              <div className="borderBottom">
                --------------------------------------------------------
              </div>
              <div className="PrivateUploadSectionBottom">
                <form>
                  {/* <input
                    type="file"
                    accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    style={{ display: "none" }}
                    ref={hiddenFileInput2}
                    onChange={fileUploadPortfolio}
                  /> */}
                  <Button
                    className="PrivateUploadButton"
                    onClick={() => handleViewCask()}
                  >
                    <div className="PrivateuploadIcon">
                      <img src={UploadIcon} />
                    </div>
                    <div className="uploadIconText"> View  Casks</div>
                  </Button>
                </form>
                <div className="PrivatesectionCaptionOne">View Private Casks</div>
                <div className="PrivatesectionCaptionSecond">
                  Click if you want to see the uploaded <b>private Casks</b> 
                </div>
                {/* <div className="PrivatesectionCaptionThree">
                  Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price
                </div> */}
              </div>
            </div>
            <div className="PrivateUploadSectionRight">
              <img src={PanelRightImage} />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default BrokerUploadPrivate;
