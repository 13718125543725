import React, { useEffect, useState } from "react";
import '../FeedbackAddModal/index.scss';
import Modal from "react-bootstrap/Modal";

export default function FeedbackView(props) {
  const [modalShow, setModalShow] = useState(false);
  const [feedback, setFeedback] = useState(null);
  useEffect(() => {
    console.log("props", props);
    setModalShow(props.setDisplay);
    setFeedback(props.feedback);
  }, [props]);
  return (
    <Modal show={modalShow} className="ModalOuterDiv">
          <Modal.Header
            //   closeButton onClick={() => props.handleModalAction(2)}
          style={{border:'none',dispay:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}
          >
        <div className="modalHeadingFeedback">Feedback</div>
      </Modal.Header>
      <Modal.Body>
        {feedback && (
          <div className="feedbackModalBody">
            <div className="memberInputRow">
              <textarea
                disabled
                rows="10" // Specifies the number of visible text lines
                cols="100" // Specifies the width of the textarea (in characters)
                // options={nameOptions}
                // onChange={(e) => {onFeedbackChange(e)}}
                // defaultValue={name}
                value={feedback.feedback}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  maxHeight: "200px", // Sets the maximum height for the textarea
                  overflow: "auto", // Adds a scrollbar if content overflows
                  resize: "none", // Optional: Disable manual resizing by the user
                }}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer style={{border:'none',dispay:'flex',flexDirection:'column'}}>
        {/* <div className="btnRowError"> */}
       
        <button
          className="btnCancelFeedback"
          onClick={() => props.handleModalAction(2)}
        >
          CLOSE
        </button>
        {/* </div> */}
      </Modal.Footer>
    </Modal>
  );
}
