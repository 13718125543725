import React, { useEffect, useState } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import Button from "react-bootstrap/Button";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import { Link } from "react-router-dom";
import "./index.scss";

const MarketPrivate = () => {
  const [marketPrivateData, setMarketPrivateData] = useState([]);
  const [multiselectValSet, setMultiselectValSet] = useState([]);
  const userData = getUserData();
  const token = getToken();
  const multiSelectValueTrigger = (data) => {
    if (multiselectValSet.includes(Number(data.target.value)) == true) {
      var index = multiselectValSet.indexOf(Number(data.target.value));
      if (index !== -1) {
        multiselectValSet.splice(index, 1);
      }
      setMultiselectValSet([...multiselectValSet]);
    } else {
      if (data.target.checked == true) {
        setMultiselectValSet([...multiselectValSet, Number(data.target.value)]);
      }
    }
  };

  const loadPrivateMarketDataHandler = async () => {
    const response = await authenticationManagementAPIs.groupAdminMarketPrivate(
      token,
      JSON.stringify(userData.user_id)
    );
    setMarketPrivateData(response.data);
  };
  useEffect(() => {
    loadPrivateMarketDataHandler();
  }, []);
  return (
    <>
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {marketPrivateData &&
              marketPrivateData.map((marketData, index) => {
                return (
                  <tr key={index}>
                    <td data-label="DISTILLERY">{marketData.distillery}</td>
                    <td data-label="AYS">{marketData.ays}</td>
                    <td data-label="CASKTYPE">{marketData.casktype}</td>
                    <td data-label="LOA">{marketData.loa}</td>
                    <td data-label="ABV">{marketData.abv} %</td>
                    <td data-label="OLA/RLA">{marketData.ola_rla}</td>
                    <td data-label="PRICE">
                      £{PriceComponent(marketData.price)}
                    </td>
                    <td data-label="ACTION">
                      <Link
                        to="/individualadmin/marketplace/buycask/private"
                        state={{ data: marketData }}
                      >
                        <Button className="buyButton" value={index}>
                        REQUEST
                        </Button>
                      </Link>
                      <input
                        type="checkbox"
                        value={marketData.cask_id}
                        className="multiSelectCheckBox"
                        onChange={(e) => multiSelectValueTrigger(e)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        {marketPrivateData && (
          <div>
            <Link
              to="/individualadmin/marketplace/buycask_multiple/private"
              state={{ data: multiselectValSet }}
            >
              <Button
                className="multiSelect buyMultiButton"
                disabled={multiselectValSet.length > 1 ? false : true}
              >
                REQUEST SELECTED {" (" + multiselectValSet.length + ")"}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default MarketPrivate;
