import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import BrokerUploadList from "../pages/Broker/BrokerCaskUpload/BrokerUploadList";
import BrokerUploadPrivate from "../pages/Broker/BrokerCaskUpload/BrokerUploadPrivate";
import CaskRequestMain from "../pages/Broker/CaskRequest";
import Transactions from "../pages/Broker/BrokerTransactions";
import Wallet from "../pages/Broker/BrokerWallet";
import WalletDeposit from "../pages/Broker/BrokerWalletDeposit";
import WalletWithdraw from "../pages/Broker/BrokerWalletWithdraw";
import Auth from "../components/Auth";
import BrokerAdminDashboard from "../pages/Broker/BrokerAdminDashboard";
import BrokerAdminSideBar from "../components/brokerAdminSideBar";
import Stripe from "../pages/Broker/Stripe";
import PayoutRequest from "../pages/Broker/BrokerPayoutRequest";
import UserMappingBroker from "../pages/Broker/UserMapping";
import UserPortfolioSuperAdmin from "../pages/Broker/UserPortfolio";
import UserPortfolioViewSuperAdmin from "../pages/Broker/UserPortfolio/view";
import Feedback from "../pages/Shared/Feedback"
import BrokerUploadListPublic from "../pages/Broker/BrokerCaskUpload/BrokerCaskUploadPublic";
import ViewCask from "../pages/Broker/BrokerCaskUpload/BrokerCaskUploadPublic/ViewCasks";
import PrivateViewCask from "../pages/Broker/BrokerCaskUpload/BrokerUploadPrivate/ViewCasks";
const BrokerRoutes = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const location = useLocation();
  return (
    <>
      <BrokerAdminSideBar openSidebar={openSidebar} />
      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <BrokerAdminDashboard />
            </Auth>
          }
        ></Route>
        <Route
          exact
          path="/upload_private/"
          element={
            <Auth>
              <BrokerUploadPrivate />
            </Auth>
          }
        />
        <Route
          exact
          path="/upload/:pagetype"
          element={
            <Auth>
              <BrokerUploadList />
            </Auth>
          }
        />
        <Route
          exact
          path="/upload/public"
          element={
            <Auth>
              <BrokerUploadListPublic />
            </Auth>
          }
        />
        <Route
          exact
          path="/upload/public/cask"
          element={
            <Auth>
              <ViewCask />
            </Auth>
          }
        />
         <Route
          exact
          path="/upload/private/cask/:id"
          element={
            <Auth>
              <PrivateViewCask />
            </Auth>
          }
        />
        <Route
          exact
          path="/cask-request"
          element={
            <Auth>
              <CaskRequestMain />
            </Auth>
          }
        />
        <Route
          exact
          path="/transactions"
          element={
            <Auth>
              <Transactions />
            </Auth>
          }
        />
        <Route
          exact
          path="/wallet"
          element={
            <Auth>
              <Wallet />
            </Auth>
          }
        />
        <Route
          exact
          path="/wallet/deposit"
          element={
            <Auth>
              <WalletDeposit />
            </Auth>
          }
        />
        <Route
          exact
          path="/wallet/withdraw"
          element={
            <Auth>
              <WalletWithdraw />
            </Auth>
          }
        />
        <Route
          exact
          path="/stripe/test"
          element={
            <Auth>
              <Stripe />
            </Auth>
          }
        />
        <Route
          exact
          path="/payout-request"
          element={
            <Auth>
              <PayoutRequest />
            </Auth>
          }
        />
        <Route
          exact
          path="/user-mapping"
          element={
            <Auth>
              <UserMappingBroker />
            </Auth>
          }
        />
        <Route
          exact
          path="/user-portfolio"
          element={
            <Auth>
              <UserPortfolioSuperAdmin />
            </Auth>
          }
        />
        <Route
          exact
          path="/user-portfolio/view"
          element={
            location.state ? (
              <Auth>
                <UserPortfolioViewSuperAdmin />
              </Auth>
            ) : (
              <Navigate replace to={"/broker/user-portfolio/"} />
            )
          }
        />
        <Route
          path="/feedback"
          element={
            <Auth>
              <Feedback />
            </Auth>
          }
        />
      </Routes>
    </>
  );
};

export default BrokerRoutes;
